import GatsbyImage, { FluidObject } from "gatsby-image";
import React from "react";
import { blogImage } from "./blog-image.module.css";

export interface BlogImageProps {
  className?: string;
  image: FluidObject;
}

export const BlogImage = (props: BlogImageProps) => {
  const { className = "", image } = props;
  return (
    <div
      className={`title-image rounded-xl shadow-xl overflow-hidden ${blogImage}`}
    >
      <GatsbyImage fluid={image} />
    </div>
  );
};
