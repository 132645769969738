import React from "react";
import { BsCalendar } from "react-icons/bs";
import { BlogAuthor } from "../../../../lib/model/Blog";
import { AuthorAvatar } from "../../shared/AuthorAvatar/AuthorAvatar";

export interface BlogSubheaderProps {
  className?: string;
  author?: BlogAuthor;
  date: Date;
}

export const BlogSubheader = (props: BlogSubheaderProps) => {
  const { className = "", author, date } = props;
  return (
    <div className="subheader mt-6 mb-6 lg:mb-10 flex space-x-8 items-center">
      {author && (
        <div className="author flex space-x-5 items-center">
          <AuthorAvatar image={author.avatar.childImageSharp.fluid} />
          <div className="author-name text-gray-500 text-lg">
            {author.nickname}
          </div>
        </div>
      )}
      <div className="date flex space-x-3">
        <div className="icon flex items-center">
          <BsCalendar />
        </div>
        <div className="value text-gray-500 text-lg">
          {new Date(date).toLocaleDateString("sk")}
        </div>
      </div>
    </div>
  );
};
