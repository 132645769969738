import GatsbyImage, { FluidObject } from "gatsby-image";
import React from "react";

export interface AuthorAvatarProps {
  className?: string;
  image: FluidObject;
}

export const AuthorAvatar = (props: AuthorAvatarProps) => {
  const { image } = props;
  return (
    <div className="author h-8 w-8 rounded-full overflow-hidden shadow-md">
      <GatsbyImage fluid={image} />
    </div>
  );
};
