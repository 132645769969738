import { graphql } from "gatsby";
import { css } from "linaria";
import React from "react";
import { BlogContentRenderer } from "../../components/Blog/SingleBlog/BlogContentRenderer/BlogContentRenderer";
import { BlogHeader } from "../../components/Blog/SingleBlog/BlogHeader/BlogHeader";
import { ContentRenderer } from "../../components/Shared/ContentRenderer/ContentRenderer";
import Layout from "../../components/Shared/Layout/Layout";
import { SEO } from "../../components/Shared/SEO/SEO";
import { Blog } from "../../lib/model/Blog";
const removeMd = require('remove-markdown');


export interface BlogTemplateProps {
  className?: string;
  data: {
    strapiBlog: Blog;
  };
}

export const query = graphql`
  query GetPost($id: String!) {
    strapiBlog(id: { eq: $id }) {
      id
      title
      slug
      content
      author {
        email
        nickname
        username
        avatar {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      date
    }
  }
`;

const BlogTemplateInner = (props: BlogTemplateProps) => {
  const {
    data: { strapiBlog: post },
  } = props;

  return (
    <Layout>
      <SEO title={post.title} image={post.image.publicURL} description={removeMd(post.content).slice(0,155)} />
      <div className="container w-full md:w-2/3 xl:w-1/2 mx-auto px-6">
        <BlogHeader
          title={post.title}
          image={post.image.childImageSharp.fluid}
          author={post.author}
          date={post.date}
        />
        <div className="content mt-8 md:mt-12 xl:mt-16">
          <ContentRenderer source={post.content} />
        </div>
      </div>
    </Layout>
  );
};

export default BlogTemplateInner;
