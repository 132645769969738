import GatsbyImage, { FluidObject } from "gatsby-image";
import { BsCalendar } from "react-icons/bs";
import React from "react";
import { BlogAuthor } from "../../../../lib/model/Blog";
import { AuthorAvatar } from "../../shared/AuthorAvatar/AuthorAvatar";
import { BlogImage } from "../BlogImage/BlogImage";
import { BlogSubheader } from "../BlogSubheader/BlogSubheader";
import { BlogContentRenderer } from "../BlogContentRenderer/BlogContentRenderer";

export interface BlogHeaderProps {
  className?: string;
  title: string;
  image: FluidObject;
  author?: BlogAuthor;
  date: Date;
}

export const BlogHeader = (props: BlogHeaderProps) => {
  const { title, image, author, date } = props;

  return (
    <div className={`className`}>
      <h1 className="text-primary font-semibold lg:font-normal text-3xl md:text-4xl lg:text-5xl">{title}</h1>
      <BlogSubheader author={author} date={date} />
      <BlogImage image={image} />
    </div>
  );
};
